
export const state = {
    backoffice_application_loaded: false,
    backoffice_application_tab_index: 0,
};

export const getters = {
    getterBackofficeApplicationLoaded: state => state.backoffice_application_loaded,
    getterBackofficeApplicationTabIndex: state => state.backoffice_application_tab_index,
};

export const mutations = {
    SET_BACKOFFICE_APPLICATION_LOADED(state, newValue) {
        state.backoffice_application_loaded = newValue
    },
    SET_BACKOFFICE_APPLICATION_TAB_INDEX(state, newValue) {
        state.backoffice_application_tab_index = newValue
    },
};

export const actions = {
    initBackofficeApplicationTabValues({ commit }) {
        commit('SET_BACKOFFICE_APPLICATION_LOADED', false);
        commit('SET_BACKOFFICE_APPLICATION_TAB_INDEX', 0);
    },
    setBackofficeApplicationTabValues({ commit }, values) {
        commit('SET_BACKOFFICE_APPLICATION_LOADED', true);
        commit('SET_BACKOFFICE_APPLICATION_TAB_INDEX', values.tabIndex);
    },
};
