
export const state = {
    fee_loaded: false,
    fee_tab_index: 0,
    fee_sel_academic_year_id: 0,
};

export const getters = {
    getterFeeLoaded: state => state.fee_loaded,
    getterFeeTabIndex: state => state.fee_tab_index,
    getterFeeSelAcademicYearId: state => state.fee_sel_academic_year_id,
};

export const mutations = {
    SET_FEE_LOADED(state, newValue) {
        state.fee_loaded = newValue
    },
    SET_FEE_TAB_INDEX(state, newValue) {
        state.fee_tab_index = newValue
    },
    SET_FEE_SEL_ACADEMIC_YEAR_ID(state, newValue) {
        state.fee_sel_academic_year_id = newValue
    },
};

export const actions = {
    initFeeTabValues({ commit }) {
        commit('SET_FEE_LOADED', false);
        commit('SET_FEE_TAB_INDEX', 0);
        commit('SET_FEE_SEL_ACADEMIC_YEAR_ID', 0);
    },
    setFeeTabValues({ commit }, values) {
        commit('SET_FEE_LOADED', true);
        commit('SET_FEE_TAB_INDEX', values.tabIndex);
        commit('SET_FEE_SEL_ACADEMIC_YEAR_ID', values.academicYearId);
    },
};
