
export const state = {
    student_account_loaded: false,
    student_account_tab_index: 0,
    student_invoice: null
};

export const getters = {
    getterStudentAccountLoaded: state => state.student_account_loaded,
    getterStudentAccountTabIndex: state => state.student_account_tab_index,
    getterStudentInvoice: state => state.student_invoice
};

export const mutations = {
    SET_STUDENT_ACCOUNT_LOADED(state, newValue) {
        state.student_account_loaded = newValue
    },
    SET_STUDENT_ACCOUNT_TAB_INDEX(state, newValue) {
        state.student_account_tab_index = newValue
    },
    SET_STUDENT_INVOICE(state, newValue) {
        state.student_invoice = newValue
    },
};

export const actions = {
    initStudentAccountTabValues({ commit }) {
        commit('SET_STUDENT_ACCOUNT_LOADED', false);
        commit('SET_STUDENT_ACCOUNT_TAB_INDEX', 0);
    },
    setStudentAccountTabValues({ commit }, values) {
        commit('SET_STUDENT_ACCOUNT_LOADED', true);
        commit('SET_STUDENT_ACCOUNT_TAB_INDEX', values.tabIndex);
    },
    setStudentInvoice({ commit }, values) {
        commit('SET_STUDENT_INVOICE', values);
    },
};
